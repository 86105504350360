import App from 'global/lists/apps';
import {
  UNIDENTIFIED_APPS_LABEL,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/AppsFilterSection/AppsFilterSectionUtils';
import {
  GithubSubDivisions,
  isGithubSubDivision,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/GithubChannelsUtils';
import {
  isSlackSubDivision,
  SlackSubDivisions,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';
import DebuggerConsole from 'utils/DebuggerConsole';

const appToDisplayName: Record<App, string> = {
  [App.AKOODA]: 'Akooda',
  [App.SLACK]: 'Slack',
  [App.GOOGLE]: 'Google',
  [App.GOOGLE_DRIVE]: 'Google Drive',
  [App.GOOGLE_CALENDAR]: 'Google Calendar',
  [App.GOOGLE_MEET]: 'Google Meet',
  [App.DROPBOX]: 'Dropbox',
  [App.ATLASSIAN]: 'Atlassian',
  [App.ATLASSIAN_JIRA]: 'Jira',
  [App.ATLASSIAN_CONFLUENCE]: 'Confluence',
  [App.GITHUB]: 'Github',
  [App.MONDAY]: 'Monday',
  [App.FIGMA]: 'Figma',
  [App.NOTION]: 'Notion',
  [App.GOOGLE_GMAIL]: 'Google Gmail',
  [App.INTERCOM]: 'Intercom',
  [App.PHABRICATOR]: 'Phabricator',
  [App.PHABRICATOR_BRANCHES]: 'Phabricator Branches',
  [App.PHABRICATOR_TICKETS]: 'Phabricator Tickets',
  [App.BITBUCKET]: 'Bitbucket',
  [App.ZOOM]: 'Zoom',
  [App.SALESFORCE]: 'Salesforce',
  [App.ZENDESK]: 'Zendesk',
  [App.BOX]: 'Box',
  [App.AIRTABLE]: 'Airtable',
  [App.M365]: 'Microsoft 365',
  [App.M365_SHAREPOINT]: 'Sharepoint',
  [App.M365_ONEDRIVE]: 'OneDrive',
  [App.ASANA]: 'Asana',
  [App.LINEAR]: 'Linear',
  [App.HUBSPOT]: 'Hubspot',
  [App.FRONT]: 'Front',
  [App.MIRO]: 'Miro',
  [App.M365_EXCHANGE]: 'Microsoft 365 Exchange',
};

function getAppDisplayName(appId: App | number | string): string {
  try {
    if (typeof appId === 'string') {
      if (isSlackSubDivision(appId)) {
        return SlackSubDivisions[appId];
      }
      if (isGithubSubDivision(appId)) {
        return GithubSubDivisions[appId];
      }

      if (appId === UNIDENTIFIED_APPS_LABEL) {
        return appId;
      }
    }

    const typedAppId = Number(appId) as App;
    const displayName = appToDisplayName[typedAppId];
    if (displayName) {
      return displayName;
    }

    DebuggerConsole.error('appId is not mapped to display name', appId, Error().stack);
  } catch (err) {
    DebuggerConsole.error('Invalid appId', appId, err);
  }
  return '';
}

export default getAppDisplayName;

import React from 'react';

import LoadingDots from 'common-ui-components/LoadingDots';

import style from 'screens/platform/directory/components/ProfileTabs/components/InteractionsCount/style.module.scss';

interface Props {
  totalAtomsCount: number;
  filteredAtomsCount: number;
  loading: boolean;
}

export default function InteractionsCount({
  filteredAtomsCount,
  totalAtomsCount,
  loading,
}: Props) {
  if (totalAtomsCount === 0) return null;

  const title = `${filteredAtomsCount} out of ${totalAtomsCount}`;

  return (
    <div className={style.interactionsStatus}>
      {loading ? <LoadingDots /> : title}
    </div>
  );
}

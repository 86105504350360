import classNames from 'classnames';
import React, {
  ReactNode, useEffect, useMemo, useRef, useState,
} from 'react';

import style from 'common-ui-components/ConditionalVisibility/conditionalVisibilityStyle.module.scss';

export default function ConditionalVisibility(
  { isVisible, className, children }:
    { isVisible: boolean; className?: string; children: ReactNode },
) {
  const [shouldMount, setShouldMount] = useState(isVisible);
  const [isInternallyVisible, setIsInternallyVisible] = useState(false);
  const currentIsVisible = useRef(isVisible);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeout;

    currentIsVisible.current = isVisible;
    if (isVisible) {
      setShouldMount(true);
      setIsInternallyVisible(false);
    } else {
      setIsInternallyVisible(false);
      timeout = setTimeout(() => {
        if (!currentIsVisible.current) setShouldMount(false);
      }, 300);
    }

    return () => clearTimeout(timeout);
  }, [isVisible]);

  useEffect(() => {
    let timeout;
    if (shouldMount) {
      timeout = setTimeout(() => setIsInternallyVisible(true), 10);
    }
    return () => clearTimeout(timeout);
  }, [shouldMount]);

  const contentDimensions = useMemo(
    () => (contentRef.current
      ? {
        width: contentRef.current.offsetWidth || undefined,
        height: contentRef.current.offsetHeight || undefined,
      } : undefined),
    [contentRef.current],
  );

  return shouldMount ? (
    <div className={classNames(style.wrapper, className)} style={contentDimensions}>
      <div className={style.conditionallyVisible}>
        <div className={isInternallyVisible ? style.active : style.inactive} ref={contentRef}>
          { children }
        </div>
      </div>
    </div>
  ) : null;
}

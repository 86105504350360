import React, { useEffect } from 'react';

import Authentication, { JwtToken } from 'global/authentication/Authentication';
import FirebaseRedirectionIndicator from 'global/authentication/FirebaseRedirectionIndicator';
import { sendTokenToChromeExtension } from 'global/ChromeExtensionUtils';
import { LoginMethod } from 'screens/platform/cross-platform-components/context/user/UserActions';
import {
  PopulatedUserState,
  useUserContext,
} from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';

function UserAuthenticator({ children }) {
  const {
    startUserSession, endUserSession, setAuthError, setFirebaseId,
  } = useUserContext();

  async function populateFirebaseId() {
    const firebaseUser = await Authentication.getAuthenticatedUser();
    if (firebaseUser) {
      const authenticationToken: JwtToken = await firebaseUser.getIdToken();
      sendTokenToChromeExtension(authenticationToken);
      setFirebaseId(firebaseUser?.uid);
    }
  }

  useEffect(() => {
    async function authenticateWithFirebase(
      authenticationCall: () => Promise<PopulatedUserState | null>,
      loginMethod: LoginMethod,
    ): Promise<boolean> {
      let isUserAuthenticated = false;
      const user = await authenticationCall();
      if (user) {
        startUserSession(user, loginMethod);
        isUserAuthenticated = true;
        populateFirebaseId();
      }

      return isUserAuthenticated;
    }

    async function authenticateUser() {
      let isUserAuthenticated = false;

      try {
        isUserAuthenticated = await authenticateWithFirebase(
          Authentication.fetchUserFromFirebase,
          'cached_firebase_user',
        );

        if (!isUserAuthenticated) {
          isUserAuthenticated = await authenticateWithFirebase(
            Authentication.fetchUserFromEmailActivationLink,
            'email_activation_link',
          );
        }
      } catch (err: any) {
        if (err.status && err.status !== 401) {
          DebuggerConsole.error(err);
        }
        setAuthError(err.message);
      } finally {
        FirebaseRedirectionIndicator.reset();
        if (!isUserAuthenticated) endUserSession();
      }
    }

    authenticateUser();
  }, []);

  return (
    <div>
      { children }
    </div>
  );
}

export default UserAuthenticator;

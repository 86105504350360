import classNames from 'classnames';
import React, { FC, SVGProps } from 'react';

import Button from 'common-ui-components/Button';
import { KEYBOARD_NAVIGATION_OPTION_CLASS_NAME } from 'common-ui-components/Dropdown/keyboardNavigationHook';
import IconButton from 'common-ui-components/IconButton';

import { ReactComponent as EnterIcon } from 'assets/img/icon/enter.svg';

import style from 'screens/platform/cross-platform-components/Navbar/components/SearchBar/SearchBarResults/SearchBarResultsSection/style.module.scss';

interface Props {
  onClick: () => void;
  title: string;
  subtitle: string;
  icon: FC<SVGProps<SVGSVGElement>>;
}

export default function EmptyResultsButton({
  onClick, title, subtitle, icon: Icon,
}: Props) {
  return (
    <Button
      onClick={onClick}
      className={classNames(
        style.searchBarResultsItemWrapper,
        KEYBOARD_NAVIGATION_OPTION_CLASS_NAME,
      )}
    >
      <div className={style.searchResultItem}>
        <div className={style.circleIcon}>
          <Icon className={style.icon} />
        </div>
        <div className={style.itemData}>
          <h4 className={style.title}>{title}</h4>
          <span className={style.subtitle}>{subtitle}</span>
        </div>
        <IconButton Icon={EnterIcon} className={style.enterIcon} />
      </div>
    </Button>
  );
}

import classNames from 'classnames';
import React, {
  ChangeEvent, Dispatch, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import BetaBadge from 'common-ui-components/Badge/BetaBadge';
import Button from 'common-ui-components/Button';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import {
  ENTERPRISE_SEARCH_QUESTION_KEY,
} from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsGenerator';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useKeystrokesListener, useMountedState, useQuery } from 'utils/hooks';

import { ReactComponent as SearchButtonIcon } from 'assets/img/icon/arrow-right-icon.svg';
import { ReactComponent as MagnifyingGlassIcon } from 'assets/img/icon/magnifying-glass-icon.svg';
import { ReactComponent as ClearIcon } from 'assets/img/icon/x-icon.svg';

import style from 'es-src/screens/HomeScreen/components/EnterpriseSearch/SearchInput/style.module.scss';

type Props = {
  searchQuery: string;
  setSearchQuery: Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  customSearchHandler?: () => void;
  className?: string;
  compact?: boolean;
} & Pick<HTMLInputElement, 'autofocus'>

const MAX_LENGTH = 150;

export default function SearchInput({
  searchQuery,
  setSearchQuery,
  placeholder,
  autofocus,
  customSearchHandler,
  className,
  compact = false,
}: Props) {
  const query = useQuery();
  const history = useHistory();
  const {
    originalQuestion, loading, handleEnterpriseSearch, handleAbortSearch, isResultCardOpen,
  } = useEnterpriseSearchContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(autofocus);
  const iconMode = useIconMode(loading, searchQuery, originalQuestion);
  const compactMode = compact || isResultCardOpen || loading;

  useEffect(() => {
    const questionParam = query.get(ENTERPRISE_SEARCH_QUESTION_KEY);
    if (questionParam) {
      setSearchQuery(questionParam);
      handleSearch(questionParam);
      query.delete(ENTERPRISE_SEARCH_QUESTION_KEY);
      history.replace({
        search: query.toString(),
      });
    }
  }, []);

  useEffect(() => {
    setSearchQuery(originalQuestion);
  }, [originalQuestion]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isSearchButtonDisabled) {
      event.preventDefault();
      handleSearch(searchQuery);
    }
  };

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  function handleSearch(value: string) {
    setFocused(false);
    if (customSearchHandler) {
      customSearchHandler();
      return;
    }
    handleEnterpriseSearch(value, 'userInput');
    DebuggerConsole.log('Search value:', value);
  }
  const isSearchButtonDisabled = searchQuery.length < 1;

  function handleSearchIconClick() {
    if (isSearchButtonDisabled) return;
    if (iconMode === 'clear') {
      if (loading) {
        handleAbortSearch(searchQuery);
      } else {
        setSearchQuery('');
      }
      inputRef.current?.focus();
    } else {
      handleSearch(searchQuery);
    }
  }

  useEffect(() => {
    if (!loading && inputRef.current && !isSearchButtonDisabled) {
      const inputElement = inputRef.current;
      inputElement.focus();
      // Move the cursor to the end of the input value
      const valueLength = inputElement.value.length;
      inputElement.setSelectionRange(valueLength, valueLength);
    }
  }, [loading, isSearchButtonDisabled]);

  useKeystrokesListener({
    '/': () => {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 1); // So the '/' won't be typed after the input element is focused
    },
  });

  return (
    <div className={classNames(
      style.searchContainer,
      loading && style.disabled,
      focused && style.focused,
      compactMode && style.compact,
      className,
    )}
    >
      <MagnifyingGlassIcon className={style.magnifyingGlassIcon} />
      <input
        type="search"
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        maxLength={MAX_LENGTH}
        ref={inputRef}
        autoFocus={autofocus}
        spellCheck={false}
        disabled={loading}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={style.searchInput}
      />
      <div className={style.actions}>
        <BetaBadge disabled={loading} />
        <Button
          transparent
          onClick={handleSearchIconClick}
          className={style.searchButtonIcon}
          disabled={isSearchButtonDisabled}
          data-testid={iconMode === 'clear' ? 'clear-button' : 'search-button'}
        >
          {iconMode === 'clear'
            ? <ClearIcon className={style.clearIcon} />
            : <SearchButtonIcon className={style.searchIcon} />}
        </Button>
      </div>
    </div>
  );
}

function useIconMode(loading: boolean, searchQuery: string, originalQuestion: string): 'clear' | 'search' {
  const [iconMode, setIconMode] = useMountedState<'clear' | 'search'>('search');

  useEffect(() => {
    // When the user edits the input, we go back to "search" mode,
    // but when searchQuery is programmatically updated to reflect the originalQuestion,
    // we skip this step
    if (searchQuery !== originalQuestion) {
      setIconMode('search');
    }
  }, [searchQuery, originalQuestion]);

  useEffect(() => {
    // When an enterprise search query was sent,
    // `loading` becomes `true` and we should switch to "clear" mode
    if (loading) {
      setIconMode('clear');
    }
  }, [loading]);

  return iconMode;
}

import classNames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';

import Button from 'common-ui-components/Button';
import firebase from 'firebase/compat/app';
import Authentication from 'global/authentication/Authentication';
import EmailForm from 'screens/public/LoginPage/LoginEmailSlide/EmailForm';
import DebuggerConsole from 'utils/DebuggerConsole';
import StringUtils from 'utils/StringUtils';

import style from 'screens/public/LoginPage/LoginEmailSlide/style.module.scss';
import loginPageStyle from 'screens/public/LoginPage/style.module.scss';

interface Props {
  visible: boolean;
  changeVisibleSlide: () => void;
}

export enum EmailStatus {
  INVALID,
  READY,
  SENDING,
  SENT
}

enum EmailLoginErrorMsg {
  INVALID_EMAIL = 'Please provide a valid email address',
  GENERAL_ERROR = 'An error has occurred while sending the email. Please try again',
  OPERATION_NOT_ALLOWED = 'Our server has experienced an error. Contact support@akooda.co for assistance.',
}

function getErrorMessage(err) {
  switch ((err as firebase.FirebaseError).code) {
    case 'auth/invalid-email':
      return EmailLoginErrorMsg.INVALID_EMAIL;
    case 'auth/operation-not-allowed':
      return EmailLoginErrorMsg.OPERATION_NOT_ALLOWED;
    default:
      return EmailLoginErrorMsg.GENERAL_ERROR;
  }
}

export default function LoginEmailSlide({ visible, changeVisibleSlide }: Props) {
  const [emailStatus, setEmailStatus] = useState(EmailStatus.INVALID);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [email, setEmail] = useState('');

  const isFirstRender = useRef(true);

  const visibilityClass = useMemo(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return visible ? undefined : style.disappearing;
    }
    return visible ? style.appearing : style.disappearing;
  }, [visible]);

  async function sendSignInLinkToEmail() {
    try {
      setEmailStatus(EmailStatus.SENDING);
      await Authentication.sendSignInLinkToEmail(email);
      setEmailStatus(EmailStatus.SENT);
    } catch (err) {
      DebuggerConsole.error('An error has occurred during activation link email send', err);
      setEmailStatus(EmailStatus.INVALID);
      setErrorMessage(getErrorMessage(err));
    }
  }

  function handleEmailChange(event) {
    const nextValue = event.target.value;
    const isEmailCurrentlyValid = StringUtils.isEmailValid(nextValue);
    setEmailStatus(isEmailCurrentlyValid ? EmailStatus.READY : EmailStatus.INVALID);
    setEmail(nextValue);

    if (isEmailCurrentlyValid) {
      setErrorMessage(null);
    }
  }

  return (
    <div className={classNames(
      style.loginEmailSlide,
      loginPageStyle.slide,
      visibilityClass,
    )}
    >
      <div className={style.emailSlideContent}>
        <h1>
          What&apos;s your email?
        </h1>
        <EmailForm
          emailStatus={emailStatus}
          handleEmailChange={handleEmailChange}
          errorMessage={errorMessage}
          sendSignInLinkToEmail={sendSignInLinkToEmail}
        />
        <div className={style.alternativeOptions}>
          <span>or</span>
          <Button onClick={changeVisibleSlide} className={style.goBack}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
}

import { useEffect } from 'react';

import {
  AtomSource,
  EnterpriseSearchResponse,
  OrganizationSource,
  PersonSource,
  SalesforceSource,
  Source,
  SourceType,
} from 'global/api/controller/EnterpriseSearchController';
import { EnrichedOrganization } from 'model/Organization';
import Person from 'model/Person';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';
import { Modify } from 'utils/TypescriptTricks';

export type EnrichedPersonSource = Modify<PersonSource, {
  data: (Person & { _id: string }) | undefined;
}>

export type EnrichedOrganizationSource = Modify<OrganizationSource, {
  data: EnrichedOrganization | undefined;
}>

export type EnrichedSource =
  AtomSource
  | SalesforceSource
  | EnrichedPersonSource
  | EnrichedOrganizationSource;

export type EnrichedEnterpriseSearchResponse = Modify<EnterpriseSearchResponse, {
  sources: EnrichedSource[];
}>

type SourceToDataCallback =
  ((source: Source) => Promise<EnrichedPersonSource>) |
  ((source: Source) => Promise<EnrichedOrganizationSource>) |
  ((source: Source) => AtomSource) |
  ((source: Source) => SalesforceSource);

export default function useEnrichedSourcesData(data: EnterpriseSearchResponse | null) {
  const { persons, organizations } = useMetadataContext();
  const [
    enrichedData,
    setEnrichedData,
  ] = useMountedState<EnrichedEnterpriseSearchResponse | null>(null);

  const enrichPersonSource = async (source: Source): Promise<EnrichedPersonSource> => {
    if (source.type !== 'person') {
      throw new Error('expected PersonSource');
    }

    const personId = source.data._id;
    const person = await persons.getById(personId);
    if (!person) {
      DebuggerConsole.error('Failed to retrieve person information for personId', personId);
    }
    const personData = person
      ? { _id: source.data._id, ...person }
      : undefined;
    return { ...source, data: personData };
  };

  const enrichOrganizationSource = async (source: Source): Promise<EnrichedOrganizationSource> => {
    if (source.type !== 'organization') {
      throw new Error('expected OrganizationSource');
    }

    const organizationId = source.data.id;
    const organization = await organizations.getEnrichedById(organizationId);
    if (!organization) {
      DebuggerConsole.error('Failed to retrieve organization information for organizationId', organizationId);
    }
    return { ...source, data: organization };
  };

  const enrichAtomSource = (source: Source): AtomSource => {
    if (source.type !== 'atom') {
      throw new Error('expected AtomSource');
    }

    return source;
  };
  const enrichSalesforceSource = (source: Source): SalesforceSource => {
    if (source.type !== 'salesforce') {
      throw new Error('expected SalesforceSource');
    }

    return source;
  };

  const sourceTypeToData: Record<SourceType, SourceToDataCallback> = {
    person: enrichPersonSource,
    organization: enrichOrganizationSource,
    atom: enrichAtomSource,
    salesforce: enrichSalesforceSource,
  };

  useEffect(() => {
    if (data === null) {
      setEnrichedData(null);
      return;
    }
    const fetchData = async () => {
      const enrichedSources = await Promise.all(
        data.sources.map(async (source) =>
          sourceTypeToData[source.type](source)),
      );

      setEnrichedData({
        ...data,
        sources: enrichedSources,
      });
    };

    fetchData();
  }, [data, persons, organizations]);

  return enrichedData;
}

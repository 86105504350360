import classNames from 'classnames';
import React from 'react';

import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import ImageWithFallback from 'common-ui-components/ImageWithFallback';
import Size from 'global/lists/Size';
import Person from 'model/Person';
import usePersonImage from 'screens/platform/cross-platform-components/context/metadata/hooks/PersonImageHook';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { DeepReadonly } from 'utils/TypescriptTricks';

import style from 'common-ui-components/MultiImages/ProfileImage/style.module.scss';

type Props = {
  person: DeepReadonly<Person>;
  size?: Size;
};

export default function PersonProfileImage({ person, size = Size.BIG }: Props) {
  const { getDepartmentColor } = useTenantContext();
  const imageUrl = usePersonImage(person.id);

  return (
    <CSSVariableDefiner variables={{ '--border-color': getDepartmentColor(person.department) }}>
      <ImageWithFallback
        className={classNames(
          style.profileImage,
          person.isExternal && style.external,
          size === Size.SMALL && style.small,
          size === Size.MEDIUM && style.medium,
          size === Size.BIG && style.big,
        )}
        imageUrl={imageUrl}
        name={person.name}
      />
    </CSSVariableDefiner>
  );
}

import React, { useMemo } from 'react';

import Button from 'common-ui-components/Button';
import { EmailStatus } from 'screens/public/LoginPage/LoginEmailSlide/index';

import style from 'screens/public/LoginPage/LoginEmailSlide/EmailForm/style.module.scss';

interface Props {
  emailStatus: EmailStatus;
  errorMessage: string | null;
  onClick: () => void;
}

export default function EmailSendButton({
  emailStatus,
  errorMessage,
  onClick,
}: Props) {
  const text = useMemo(() => {
    switch (emailStatus) {
      case EmailStatus.SENT:
        return 'Email sent';
      default:
        return 'Send an Activation Link';
    }
  }, [emailStatus]);

  return (
    <Button
      disabled={
        [EmailStatus.INVALID, EmailStatus.SENDING, EmailStatus.SENT].includes(
          emailStatus,
        ) || errorMessage !== null
      }
      aria-label="Send"
      className={style.sendButton}
      onClick={onClick}
      loading={emailStatus === EmailStatus.SENDING}
      type="submit"
    >
      {text}
    </Button>
  );
}

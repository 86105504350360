import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

import style from 'common-ui-components/ImageWithFallback/style.module.scss';

type Props = {
  className?: string;
  imageUrl: string | null;
  name: string;
  title?: string;
  fallbackComponent?: ReactNode;
  hideOnFallback?: boolean;
};

function getNameInitial(name: string) {
  return name.charAt(0).toUpperCase();
}

export default function ImageWithFallback({
  className,
  imageUrl,
  name,
  title,
  fallbackComponent,
  hideOnFallback = false,
}: Props) {
  const [imageNotLoaded, setImageNotLoaded] = useState<boolean>(!imageUrl);

  useEffect(() => {
    setImageNotLoaded(!imageUrl);
  }, [imageUrl]);

  if (imageNotLoaded) {
    return hideOnFallback ? null
      : (
        <div className={classNames(className, style.fallback)} title={title}>
          {
            fallbackComponent ?? getNameInitial(name)
          }
        </div>
      );
  }
  return (
    <img
      src={imageUrl || undefined}
      alt={name}
      title={title}
      onError={() => setImageNotLoaded(true)}
      className={className}
    />
  );
}

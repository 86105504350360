import classNames from 'classnames';
import React from 'react';

import Sources from 'common-ui-components/Sources';
import { Paragraph } from 'common-ui-components/Typograhpy';
import RelevantPeopleSection
  from 'es-src/screens/HomeScreen/components/ResultSection/EnterpriseSearchResult/components/RelevantPeopleSection';
import FollowupQuestions from 'es-src/screens/HomeScreen/components/ResultSection/FollowupQuestions';
import {
  EnrichedEnterpriseSearchResponse,
} from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import ConfigurationKey from 'global/lists/ConfigurationKey';
import { useConfiguration } from 'screens/platform/cross-platform-components/context/configuration/ConfigHook';
import Disclaimer from 'screens/platform/cross-platform-components/Disclaimer';

import style from 'es-src/screens/HomeScreen/components/ResultSection/EnterpriseSearchResult/components/AnswerSessionContent/style.module.scss';

interface Props {
  data: EnrichedEnterpriseSearchResponse;
  integrateRelevantPeopleInAnswer: boolean;
}

export default function AnswerSessionContent({
  data,
  integrateRelevantPeopleInAnswer,
}: Props) {
  const {
    value: isRelevantPeopleRedoEnabled,
  } = useConfiguration(
    ConfigurationKey.IS_RELEVANT_PEOPLE_REDO_ENABLED,
    'boolean',
  );

  return (
    <>
      <div className={classNames(style.answer, 'answer')}>
        <Paragraph>
          {data.answer.split('\n').map((line) => (
            <div key={crypto.randomUUID()}>
              {line}
            </div>
          ))}
          {!isRelevantPeopleRedoEnabled && integrateRelevantPeopleInAnswer && (
            <RelevantPeopleSection
              relevantPeople={data.relevantPeople}
              includeTitle={false}
            />
          )}
        </Paragraph>
        <Disclaimer sessionId={data.sessionId} isSuccessfulAnswer={data.isSuccessfulAnswer} location="Enterprise Search" />
      </div>
      <Sources sources={data.sources} />
      {!isRelevantPeopleRedoEnabled && !integrateRelevantPeopleInAnswer && (
        <RelevantPeopleSection relevantPeople={data.relevantPeople} />
      )}
      {data.isSuccessfulAnswer && (
        <FollowupQuestions searchResponse={data} />
      )}
    </>
  );
}

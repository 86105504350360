import classNames from 'classnames';
import React from 'react';

import ConditionalVisibility from 'common-ui-components/ConditionalVisibility';
import EmailSendButton from 'screens/public/LoginPage/LoginEmailSlide/EmailForm/EmailSendButton';
import { EmailStatus } from 'screens/public/LoginPage/LoginEmailSlide/index';

import style from 'screens/public/LoginPage/LoginEmailSlide/EmailForm/style.module.scss';
import loginPageStyle from 'screens/public/LoginPage/style.module.scss';

export const SENT_EMAIL_TEXT = 'Please check your inbox (including the spam folder)';

interface Props {
  emailStatus: EmailStatus;
  handleEmailChange: (event) => void;
  errorMessage: string | null;
  sendSignInLinkToEmail: () => Promise<void>;
}

export default function EmailForm({
  emailStatus,
  handleEmailChange,
  errorMessage,
  sendSignInLinkToEmail,
}: Props) {
  return (
    <div className={style.emailForm}>
      <form>
        <ConditionalVisibility
          className={style.sentEmailMsg}
          isVisible={emailStatus === EmailStatus.SENT}
        >
          {SENT_EMAIL_TEXT}
        </ConditionalVisibility>
        <div className={style.inputWrapper}>
          <input
            aria-label="Email Address"
            type="email"
            onChange={handleEmailChange}
            placeholder="Enter work email"
            className={classNames(emailStatus === EmailStatus.SENT && style.emailSent)}
            disabled={[EmailStatus.SENDING, EmailStatus.SENT].includes(emailStatus)}
            autoComplete="email"
            name="email"
          />
          <div className={classNames(
            loginPageStyle.errorMessage,
            style.errorMessage,
            errorMessage ? style.visible : style.invisible,
          )}
          >
            <span className={style.marginTop} />
            {errorMessage || ' '}
          </div>
        </div>
        <EmailSendButton
          emailStatus={emailStatus}
          errorMessage={errorMessage}
          onClick={sendSignInLinkToEmail}
        />
      </form>
    </div>
  );
}

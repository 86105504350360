import axios from 'axios';
import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Authentication from 'global/authentication/Authentication';
import { isFirebaseIgnorableException, UnauthorizedException } from 'global/authentication/AuthenticationException';
import { sendTokenToChromeExtension } from 'global/ChromeExtensionUtils';
import { AuthError, useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import RoundIconButton from 'screens/public/LoginPage/RoundIconButton';
import DebuggerConsole from 'utils/DebuggerConsole';
import useMobileWarning from 'utils/MobileUtils';

import { ReactComponent as EmailIcon } from 'assets/img/loginPage/email-icon.svg';
import { ReactComponent as GoogleIcon } from 'assets/img/loginPage/google-icon.svg';
import { ReactComponent as MicrosoftIcon } from 'assets/img/loginPage/microsoft-icon.svg';

import style from 'screens/public/LoginPage/LoginMainSlide/style.module.scss';
import loginPageStyle from 'screens/public/LoginPage/style.module.scss';

interface Props {
  visible: boolean;
  changeVisibleSlide: () => void;
}

export default function LoginMainSlide({ visible, changeVisibleSlide }: Props) {
  const { setAuthError, startUserSession } = useUserContext();
  const { isMobile, showMobileWarning } = useMobileWarning();
  const isFirstRender = useRef(true);
  const history = useHistory();

  async function loginWithAuthProvider(authProvider: 'google' | 'microsoft') {
    if (isMobile) {
      showMobileWarning();
      return;
    }

    try {
      const signInResult = await Authentication.openSignInPopup(authProvider);
      const token = await signInResult?.user?.getIdToken();
      if (token) {
        const userState = await Authentication.authorizeUserByToken(token);
        sendTokenToChromeExtension(token);
        startUserSession(userState, 'google');
        history.push('/');
      }
    } catch (err) {
      if (!isFirebaseIgnorableException(err)) {
        DebuggerConsole.error('An error occurred during login with Google credentials', err);
      }
      if (err instanceof UnauthorizedException) {
        setAuthError(AuthError.UNAUTHORIZED);
      }
      if (axios.isAxiosError(err)) {
        setAuthError((err.message as AuthError));
      }
    }
  }

  const visibilityClass = useMemo(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return undefined;
    }
    return visible ? style.appearing : style.disappearing;
  }, [visible]);

  return (
    <div className={classNames(
      loginPageStyle.slide,
      visibilityClass,
      style.loginMainSlide,
    )}
    >
      <h1>Sign in to Akooda</h1>
      <div className={style.loginButtonsWrapper}>
        <RoundIconButton
          label="Continue with Google"
          Icon={GoogleIcon}
          onClick={() => loginWithAuthProvider('google')}
          className={style.loginButton}
        />
        <RoundIconButton
          label="Continue with M365"
          Icon={MicrosoftIcon}
          onClick={() => loginWithAuthProvider('microsoft')}
          className={style.loginButton}
        />
        <RoundIconButton
          label="Continue with Email"
          Icon={EmailIcon}
          onClick={isMobile ? showMobileWarning : changeVisibleSlide}
          className={style.loginButton}
        />
      </div>
    </div>
  );
}

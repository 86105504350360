import React, { ReactNode, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { getScreenPath } from 'global/ScreensConfiguration';
import { ACCOUNT_LOGIN_PATH, LOGIN_PATH_PREFIX } from 'routers/AccountRouter';
import NotFoundPage from 'routers/NotFoundPage';
import getPlatformRoutesConfig from 'routers/PlatformRouter/platformRoutesConfig';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';

function NonPlatformContentRoute({
  path,
  children,
}: {
  path: string;
  children: ReactNode;
}) {
  const { dispatchMasterFilters } = useMasterFilters();

  useEffect(() => {
    dispatchMasterFilters({ type: 'RESET_FILTERS' });
  }, []);

  return (
    <Route path={path}>
      <Suspense fallback={null}>{children}</Suspense>
    </Route>
  );
}

export default function PlatformRouter() {
  const {
    tenant: { setupState },
  } = useTenantContext();

  const routesConfig = getPlatformRoutesConfig(setupState);
  const defaultLandingPage = getScreenPath(routesConfig.defaultScreenKey);

  return routesConfig ? (
    <Switch>
      {routesConfig.routes.map(
        ({
          Component, nonPlatformContentRoute, screenKey, exact,
        }) => {
          const path = getScreenPath(screenKey);
          const RouteWrappingComponent = nonPlatformContentRoute
            ? NonPlatformContentRoute
            : Route;
          return (
            <RouteWrappingComponent key={path} path={path} exact={exact}>
              <Component />
            </RouteWrappingComponent>
          );
        },
      )}
      <Redirect from={LOGIN_PATH_PREFIX} to={defaultLandingPage} />
      <Redirect from={ACCOUNT_LOGIN_PATH} to={defaultLandingPage} />
      <Redirect exact from="/" to={defaultLandingPage} />
      <Route path="*">
        <NotFoundPage padded />
      </Route>
    </Switch>
  ) : null;
}
